<template>
  <section class="secondary bg-white w-full">
    <h1 class="text-xl pb-4" v-html="pageData.Title">This is the about page</h1>
    <div v-html="pageData.Content"></div>
  </section>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      pageData: {},
    };
  },
  created(){
    // LOAD FROM API
    var path = this.$route.meta.path+'/';
    axios
      .get(this.$store.state.api + path)
      .then((response) => {
        // console.log(`about us: ${JSON.stringify(response.data)}`);
        var showdown = require('showdown'),
          converter = new showdown.Converter(),
          html = converter.makeHtml(response.data.Content);
        this.pageData = response.data;
        this.pageData.Content = html;
        this.$forceUpdate();
      })
      .catch((error) => console.log(error));

  },
  mounted() {
    //   console.log(this.$route)
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/helpers';
@import '../styles/typovars';
@import '../styles/shared';
    .text-xl{
        @include fluid-type($minwidth, $maxwidth, 30px, 36px);
    }

</style>
